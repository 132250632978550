import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import TopCalculators from "../components/Common/TopCalculators"
import SEO from "../components/App/SEO"

const Calculators = props => {
  const calculatorList = props.data.allCalculatorsJson.edges

  return (
    <Layout>
      <SEO
        title="Calculators | Oxygen - Home Loans Made Simple"
        description="Get started with our easy to use calculators"
      />
      <Navbar />
      <div className="page-title-area">
        <TopCalculators sectionTitle="Get started with our easy to use calculators" />
      </div>
      <section>
        <div className="container">
          <h3>Managing your finances should be easy</h3>
          <p>
            To help you out we’ve put together a collection of over 20
            calculators that will allow you to work through a number of
            scenarios and help plan your financial situation.
          </p>
        </div>
        <div className="calculator-cards pt-5 mt-5 pb-70 ">
          <div className="container">
            <h4>
              Select from the list of savings, tax and loan calculators below.
            </h4>
            <div className="row">
              {calculatorList.map((edge, index) => {
                if (index > 2) {
                  return (
                    <div key={edge.node.id} className="col-lg-4 col-md-6">
                      <Link to={edge.node.url} className="calculator-card">
                        <h5>{edge.node.name}</h5>
                        <p>{edge.node.description}</p>
                      </Link>
                    </div>
                  )
                } else return ""
              })}
            </div>
            <p>
              <i className="asterisk">*</i> Please note the results from each
              calculator should be used as an indication only. For more detailed
              information we recommend that you contact us directly.
            </p>
          </div>
        </div>
      </section>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allCalculatorsJson {
      edges {
        node {
          id
          name
          description
          url
        }
      }
    }
  }
`

export default Calculators
